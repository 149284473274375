<template>
  <div>
    <div v-resize="onResize" v-show="mobile == true">
      <v-dialog
        v-model="DialogRegisterMobile"
        scrollable
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card
          class="tile"
          :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
        >
          <v-card-actions
            style="height: 64px;"
          >
            <v-btn icon class="ml-n3 mr-1" height="47px" width="47px" @click="KeluarDialogRegisterMobile">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title class="title">Register</v-toolbar-title>
          </v-card-actions>

          <v-divider></v-divider>
          
          <v-card-text
            class="ScrollCSSHide"
          >
            <v-sheet
              class="mt-3"
            >
              <div class="title">
                <span>Register Akun TSS</span>
              </div>

              <div class="mb-10 mt-n1 subtitle-1">
                <span>Masukkan Nama Pengguna Dan Kata Sandi!</span>
              </div>

              <div>
                <span class="subtitle-1">Nama Pengguna</span>
                <v-text-field
                  color="dark"
                  class="mt-n4 mb-4"
                  single-line
                  v-model="Nama"
                  :rules="nameRules"
                  hint="Nama Pengguna Digunaka Ketika Anda Login!"
                ></v-text-field>
              </div>

              <div >
                <span class="subtitle-1">Kata Sandi</span>
                <v-text-field
                  color="dark"
                  class="mt-n4 mb-4"
                  single-line
                  v-model="password"
                  :append-icon="TampilkanPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="TampilkanPassword ? 'text' : 'password'"
                  name="input-10-1"
                  hint="Min 6 Karakter"
                  persistent-hint
                  counter
                  @click:append="TampilkanPassword = !TampilkanPassword"
                ></v-text-field>
              </div>

              <div>
                <span class="subtitle-1">Konfirmasi Sandi</span>
                <v-text-field
                  color="dark"
                  class="mt-n4 mb-4"
                  single-line
                  v-model="password2"
                  :append-icon="TampilkanPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="TampilkanPassword2 ? 'text' : 'password'"
                  name="input-10-1"
                  hint="Min 6 Karakter"
                  persistent-hint
                  counter
                  @click:append="TampilkanPassword2 = !TampilkanPassword2"
                ></v-text-field>
              </div>

              <div>
                <v-btn
                  block
                  depressed
                  height="45px"
                  :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                  class="rounded-lg"
                  @click="save"
                >
                  Register
                </v-btn>
              </div>
            </v-sheet>
          </v-card-text>

          <v-footer>
            <span class="caption">Setelah Register Akun, Verifikasi Akan Dilakukan Oleh HR.</span>
          </v-footer>
        </v-card>
      </v-dialog>
    </div>

    <v-layout class="mt-5" justify-center>
      <v-col cols="12" md="6" sm="6" v-show="mobile == false">
        <v-card
          outlined
          scrollable
          max-width="900"
          class="mx-auto elevation-15 rounded-lg"
        >
          <v-layout justify-center>
            <v-card-title class="headline">
              Daftar Akun
            </v-card-title>
          </v-layout>

          <v-layout class="mt-n8" row wrap align-center justify-center>
            <p class="subheading font-weight-regular grey--text text--darken-1 my-4">
              Sudah Punya Akun? Klik
              <router-link
                to="/Login"
                class="red--text text--darken-4 text-decoration-none"
              >
                Masuk
              </router-link>
            </p>
          </v-layout>

          <v-card-text class="mt-n4">
            <v-col>
              <v-row dense>

                <!-- Nama -->
                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    outlined
                    class="rounded-lg"
                    color="grey darken-1"
                    v-model="Nama"
                    label="Nama"
                    :rules="nameRules"
                  >
                  </v-text-field>
                </v-col>

                <!-- Password -->
                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    outlined
                    class="rounded-lg"
                    color="grey darken-1"
                    v-model="password"
                    :append-icon="TampilkanPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="TampilkanPassword ? 'text' : 'password'"
                    name="input-10-1"
                    label="Kata Sandi"
                    hint="Min 6 Karakter"
                    persistent-hint
                    counter
                    @click:append="TampilkanPassword = !TampilkanPassword"
                  ></v-text-field>
                </v-col>

                <!-- Ulangi Password -->
                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    outlined
                    class="rounded-lg"
                    color="grey darken-1"
                    v-model="password2"
                    :append-icon="TampilkanPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="TampilkanPassword2 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Konfirmasi Sandi"
                    hint="Min 6 Karakter"
                    persistent-hint
                    counter
                    @click:append="TampilkanPassword2 = !TampilkanPassword2"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn
              dark
              color="error"
              @click="close">
              <v-icon class="mr-1">mdi-close-circle</v-icon>Batal
            </v-btn> -->
            <v-btn
              dark
              block
              color="red darken-4 rounded-lg"
              class="text-capitalize"
              @click="save">
              <v-icon class="mr-1">mdi-account-plus</v-icon>Daftar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-layout>
  </div>
</template>

<script>
import api from '../services/http'
export default {
  props: ['DialogRegisterMobile'],
    data() {
        return {
          mobile:null,
          windowSize: {x: 0, y: 0},
          d: new Date(),
          date: new Date().toISOString().substr(0, 10),
          tglanlahir:'',
          Nama:"",
          password:"",
          password2:"",

					nameRules: [
						value => !!value || 'Nama Wajib Diisi.',
					],

					emailRules: [
						v => !!v || 'E-mail Wajib Diisi',
						v => /.+@.+\..+/.test(v) || 'E-mail Harus Benar',
					],
					
					rules: {
						required: value => !!value || 'Kata Sandi Wajib Diisi.',
						min: v => v.length >= 6 || 'Setidaknya 6 Karakter',
						// emailMatch: () => (`The email and password you entered don't match`),
					},
					TampilkanPassword: false,
          TampilkanPassword2: false,
          editedIndex: -1,
          defaultItem: {
          name: "",
          email: "",
          password: "",
        },
        credentials:{
        Nama: '',
        password: ''
      },

          editedItem: {
          name: "",
          password: "",
				},
				
        token : localStorage.getItem('token'),
      }
    },

    mounted(){
        // this.getDataRegister()
        
    },
    
     watch: {
       DialogRegisterMobile(value){
         console.log('Dialog Register Mobile ', value)
         this.$parent.DialogRegisterMobile = value
       },

       windowSize(){
        if (this.windowSize.x < 500) {
          this.titleClass = "d-none"
          this.mobile = true
        }else{
          this.titleClass = "mr-4"
          this.mobile = false
        }
      },

       date(){
         var tahun = this.date.substring(0, 4);
         let bulan = this.date.substring(5, 7);
         let tgl = this.date.substring(8, 10);
         this.TglLahir =  tahun+"/"+bulan+"/"+tgl
         this.tglanlahir = this.TglLahir
       }
     },
    computed: {
        
    },
    methods: {
      KeluarDialogRegisterMobile(){
        this.$parent.DialogRegisterMobile = false
      },

      onResize(){
        this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      },

        save(){
          if (this.password != this.password2) {
            alert ('Pastikan Password telah benar!')
          } else {
            api.post('/registerself',{
            Kode: "0101/"+this.Nama.toUpperCase(),
            Nama: this.Nama,
            password: this.password,
            KodeKaryawan: null,
            IsRegister: 1,
            })
            .then((res)=>{
              console.log(res)
              if (res.data.status == true) {
                this.credentials.Nama = this.Nama
                this.credentials.password = this.password
                alert('Selamat, Register telah berhasil,')
                api.post('/login', this.credentials)
                .then(res=>{
                  if (res.data.status == false) {
                  this.loadingpersen = false
                  this.pesan = "Access Denied : Email Atau Password salah"  
                  } else {
                        localStorage.setItem('token', res.data.token)
                        localStorage.setItem('user', JSON.stringify(res.data.user))
                        this.$router.push('/')
                        //triger ke appbar via root dan emit
                        this.$root.$emit('menuappbar')
                    // console.log(res)
                    
                  }

                })
                .catch(err=>{
                  console.log(err)
                  if (err == "Error: Request failed with status code 500") {
                    this.loadingpersen = false
                    alert('koneksi database bermasalah')
                  }
                  // alert('email atau password salah')
                  // confirm('email atau password salah')
                  // console.log('email atau password salah')
                })

              } else {
                alert('User Sudah Digunakan')
              }
            })
            .catch((err)=>{
              if (err == "Error: Request failed with status code 422") {
                    alert('Nama Sudah Digunakan')
                  }
              if (err) {
                //do nothing
              }
              
            })
          }
        },
    }
}
</script>
